import { NpsEnum } from "../../enum/nps.enum";

export const props = () => ({
  apiUrl: "https://api-im-prod.graodireto.com.br",
  theme: "graodireto",
  logo: "graodireto",
  keyCloakConfig: {
    url: "https://auth.graodireto.com.br",
    realm: "im",
    clientId: "im-front",
  },
  production: false,
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },
  hotjarConfig: "",
  nps: {
    api: "https://api.graodireto.com.br/api",
    url: "https://graodireto.com.br/nps",
    key: "109d2ffc-e06b-4c6a-88c2-6a1b9095dc75",
  },
  npsSourceId: NpsEnum.MARKET_INTELLIGENCE_CORPORATE,
});
